//按需加载模块
const accountPeriod = resolve => {
  require.ensure([],() => {
    resolve(require('../../components/account-period/index'))
  },'account-period')//这个参数一样的模块，会被打包在一个文件中
}
const accountPeriodContract = resolve => {
  require.ensure([],() => {
    resolve(require('../../components/account-period/contract'))
  },'account-period')//这个参数一样的模块，会被打包在一个文件中
}

//前端路由
export default [
  { path: '/account-period', component: accountPeriod, meta: { title: '月结申请' } },
  { path: '/account-period-contract', component: accountPeriodContract }
]

// 静态页面 - 按需加载模块
// 条款
const clauseVersion1 = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/clause/version-1.vue')); }, 'static-page', ); };
// 静态页面 - 按需加载模块
// 条款
const clauseVersion2 = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/clause/version-2.vue')); }, 'static-page', ); };
// 静态页面 - 按需加载模块
// 条款
const clauseVersion3 = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/clause/version-3.vue')); }, 'static-page', ); };
const aboutHll = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/about-hll.vue')); }, 'static-page', ); };
// 我的司机
const driver = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/driver.vue')); }, 'static-page', ); };
// 拉拉券
const discountsTicket = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/discounts-ticket.vue')); }, 'static-page', ); };
// 服务协议
const protocol = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/protocol.vue')); }, 'static-page', ); };
// 用户守则
const rules = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/rules.vue')); }, 'static-page', ); };
// 货拉拉服务
const serve = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/serve.vue')); }, 'static-page', ); };
// 货拉拉隐私政策
const privacyPolicy = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/privacy-policy.vue')); }, 'static-page', ); };
// 货拉拉隐私政策-外链端外广告投放审核
const privacyPolicyLink= resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/privacy-policy-link.vue')); }, 'static-page', ); };
// 理赔流程
const claimSettlement = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/claim-settlement.vue')); }, 'static-page', ); };
// 货物保险协议
const insuranceProtocol = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/insurance-protocol.vue')); }, 'static-page', ); };
// 货拉拉企业版信息服务协议
const behaviorStandard = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/behavior-standard.vue')); }, 'static-page', ); };
// 货拉拉知识产权协议
const equityProtocol = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/equity-protocol.vue')); }, 'static-page', ); };
// 货拉拉知识产权协议
const protocolText = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/protocol-text.vue')); }, 'static-page', ); };
// 充值协议
const chargeAgreement = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/charge-agreement.vue')); }, 'static-page', ); };
// 月结协议
const paymentAgreement = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/payment-agreement.vue')); }, 'static-page', ); };
// 企业注销协议
const eplogoutAgreement = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/eplogout-agreement.vue')); }, 'static-page', ); };

// 货拉拉企业版信息服务协议
const informationService = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/information-service.vue')); }, 'static-page', ); };

// 协议列表
const lawList = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/law-list.vue')); }, 'static-page', ); };

// 企业关联 子账号知情同意规则
const childEnterpriseProtocol = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/child-enterprise-protocol.vue')); }, 'static-page', ); };
const androidSdkMenu = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/android-sdk-menu.vue')); }, 'static-page', ); }
const iosSdkMenu = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/ios-sdk-menu.vue')); }, 'static-page', ); }
const personalInfoCollectionList = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/personal-info-collection-list.vue')); }, 'static-page', ); }
const thirdPartyShareList = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/third-party-share-list.vue')); }, 'static-page', ); }

// 货拉拉隐私政策历史列表
const privacyList = resolve => { require.ensure( [], () => { resolve(require('../../components/static-page/privacy-list.vue')); }, 'static-page', ); };


// 前端路由
export default [
  {
    path: '/clause',
    component: clauseVersion1,
    meta: { title: '货拉拉企业版网络货运服务协议' },
  },
  {
    path: '/clause-2',
    component: clauseVersion2,
    meta: { title: '货拉拉企业版网络货运服务协议' },
  },
  {
    path: '/clause-new',
    component: clauseVersion3,
    meta: { title: '货拉拉企业版网络货运服务协议' },
  },
  {
    path: '/about-hll',
    component: aboutHll,
    meta: { title: '关于货拉拉' },
  },
  {
    path: '/information-service',
    component: lawList,
    meta: { title: '货拉拉企业版信息服务协议' },
  },
  {
    path: '/information-service-0101',
    component: informationService,
    meta: { title: '货拉拉企业版信息服务协议' },
  },
  {
    path: '/law',
    component: lawList,
    meta: { title: '货拉拉企业版用户协议' },
  },
  {
    path: '/driver',
    component: driver,
    meta: { title: '我的司机' },
  },
  {
    path: '/discounts-ticket',
    component: discountsTicket,
    meta: { title: '优惠券使用规则及帮助' },
  },
  {
    path: '/protocol',
    component: protocol,
    meta: { title: '货物托运服务协议' },
  },
  {
    path: '/protocol-text',
    component: protocolText,
    meta: { title: '货拉拉禁限运输货物条款' },
  },
  {
    path: '/rules',
    component: rules,
    meta: { title: '货拉拉用户守则' },
  },
  {
    path: '/serve',
    component: serve,
    meta: { title: '货拉拉服务' },
  },
  {
    path: '/privacy-policy',
    component: privacyPolicy,
    meta: { title: '货拉拉隐私政策' },
  },
  {
    path: '/privacy-policy-link',
    component: privacyPolicyLink,
    meta: { title: '货拉拉隐私政策' },
  },
  {
    path: '/claim-settlement',
    component: claimSettlement,
    meta: { title: '理赔规则' },
  },
  {
    path: '/insurance-protocol',
    name: 'insuranceProtocol',
    component: insuranceProtocol,
    meta: { title: '货拉拉平台用户货物保障条款' }
  },
  {
    path: '/behavior-standard',
    component: behaviorStandard,
    meta: { title: '用户行为规范' },
  },
  {
    path: '/equity-protocol',
    component: equityProtocol,
    meta: { title: '货拉拉知识产权条款' },
  },
  {
    path: '/charge-agreement',
    name: 'chargeAgreement',
    component: chargeAgreement,
    meta: { title: '充值协议' },
  },
  {
    path: '/payment-agreement',
    name: 'paymentAgreement',
    component: paymentAgreement,
    meta: { title: '货拉拉企业版账期协议' },
  },
  {
    path: '/eplogout-agreement',
    name: 'eplogoutAgreement',
    component: eplogoutAgreement,
    meta: { title: '企业账户注销协议' },
  },
  {
    path: '/eplogout-agreement',
    name: 'eplogoutAgreement',
    component: eplogoutAgreement,
    meta: { title: '企业账户注销协议' },
  },
  {
    path: '/law-freight',
    component: lawList,
    meta: { title: '货拉拉企业版网络货运服务协议' },
  },
  {
    path: '/law-accounting-period',
    component: lawList,
    meta: { title: '货拉拉企业版账期协议' },
  },
  {
    path: '/accounts-history-list',
    component: lawList,
    meta: { title: '货拉拉企业版账期历史协议' },
  },
  {
    path: '/child-enterprise-protocol',
    component: childEnterpriseProtocol,
    meta: { title: '子账号知情同意规则' },
  },
  {
    path: '/android-sdk-menu',
    component: androidSdkMenu,
    meta: { title: '第三方SDK目录-Android' }
  },
  {
    path: '/ios-sdk-menu',
    component: iosSdkMenu,
    meta: { title: '第三方SDK目录-IOS' }
  },
  {
    path: '/privacy-policy-list',
    component: lawList,
    meta: { title: '隐私管理' },
  },
  {
    path: '/third-party-share-list',
    component: thirdPartyShareList,
    meta: { title: '第三方共享清单' }
  },
  {
    path: '/personal-info-collection-list',
    component: personalInfoCollectionList,
    meta: { title: '个人信息收集清单' }
  },
  {
    path: '/privacy-list',
    component: privacyList,
    meta: { title: '隐私政策列表' }
  }
];
